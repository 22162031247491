/* Template continer form */
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height: 150vh;
    font-family: 'Lilita One', cursive;
    color: #DF3B20;
    font-size: x-large;
}

/* Header */

.header {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 64px;
    color: #DF3B20;
    text-shadow: 2px 2px black;
    font-family: 'Lilita One', cursive;
}

/* input image field */
.input-image {
    border: 2px dashed darkcyan;
    height: 25vh;
    width: 10vw;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: large;
}

.phone::-webkit-inner-spin-button,.phone::-webkit-outer-spin-button{
    display: none;
}

/* placing the row and the row-content */

.row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    min-height: 250vh;
}

.row-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 10% 5pt;
    min-height: 15vh;
}

.row-content label{
    font-size: 32px;
}

.row-content textarea {
    width: 30vw;
    height: 15vh;
    font-family: 'Lilita One', cursive;
    padding: 5pt;
    font-size: xx-large;
}

.svg-field {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
}

.form-container button {
    margin-bottom: 10%;
    width: 10%;
    height: 5vh;
    font-family: 'Lilita One', cursive;
    font-size: 24px;
    border-radius: 10px;
    border: 2px solid blue;
    background-color: lightblue;
}

@media screen and (max-width: 768px) {
    .form-container {
        width: 100%;
        min-height: 100vh;
    }

    .header {
        font-size: xx-large;
    }

    .header img {
        height: 15vh;
    }

    .image-instruction {
        display: none;
    }

    .svg-field {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        min-height: 75vh;
    }

    .row-content {
        width: 100%;
        padding: 1rem;
    }

    .row-content textarea {
        align-self: flex-start;
        width: 75vw;
    }

    .row-content input {
        align-self: flex-start;
        width: 100% !important;
    }

    .reverse {
        display: flex;
        flex-direction: column-reverse;
    }

    .form-container button {
        width: 80%;
        height: 6vh;
    }

    .input-image {
        width: 60%;
        height: 30vh;
    }
}