@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.output-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 220vh;
  font-family: "Lilita One", cursive;
  font-weight: lighter;
}

.output-container h1 {
  font-size: 84px;
  -webkit-text-stroke: 3px #fef2f0;
  color: #c64980;
}

.slam-social-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50vh;
  flex-direction: column;
}

.name-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 30vh;
}

.social-holder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 15vh;
  width: 100%;
}

.social-holder span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  height: 10vh;
  color: #c64980;
  border-radius: 25px;
}

.social-holder span > h2 {
  font-size: 48px;
  text-shadow: 4px 4px white;
  -webkit-text-stroke: 2px white;
}

.label-info-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  text-align: center;
  min-height: 15vh;
  padding: 5px;
  border-radius: 20px;
  -webkit-text-stroke: 0.5px #fef2f0;
}

.label-info-holder label {
  font-size: 54px;
  color: #c64980;
}

.label-info-holder p {
  font-size: 48px;
  color: rgb(0, 90, 255);
}

.output-container button:active {
  transform: translate3d(2px, 2px, 3px);
}

.back-button {
  transition: ease-in-out 0.4s;
}

.back-button:hover {
  transform: scale(1.4);
}

.label-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vh;
  min-height: 100vh;
  margin-top: 5%;
}

@media screen and (max-width: 992px) {
  .output-container {
    max-width: 100%;
  }
  .output-container h1 {
    font-size: 36px;
    text-align: center;
    padding: 5px;
  }

  .social-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 60vh;
  }

  .social-holder img {
    height: 30px;
    align-items: flex-start;
  }

  .social-holder span > h2 {
    font-size: 30px;
  }

  .label-info-holder {
    text-align: center;
  }

  .label-info-holder label {
    font-size: x-large;
  }

  .label-info-holder p {
    font-size: xx-large;
  }

  .back-button {
    height: 5vh;
  }

  .label-info-container {
    margin-top: 10%;
  }
}