.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.landingPageBgImg {
    background-size: cover;
    min-height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4);
}